.tableScrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.tableScrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.tableScrollbar::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
  border: 2px solid #f1f1f1;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.tableScrollbar::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
