.select {
  height: 40px;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 8px;
  border: 1px solid #868380;
  border-radius: 12px;
  color: #ada9a5;
  width: 100%;

  -webkit-appearance: none;
  appearance: none;
  background-image: url('/arrow-down.svg');
  background-size: 26px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;

  .select option {
    color: black;
  }
}

.select:focus {
  color: black;
}
